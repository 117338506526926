import React from "react"
import Layout from "components/Layout/en"
import partnershipImg from "../partnershipImg.jpg"
import { Container, Row, Col, Button } from "react-bootstrap"
import BasicPartnerLogo from "../Basic.png"
import GoldPartnerLogo from "../Gold.png"
import Image from "components/Image/index"

export default function(props) {
  const header = {
    x: 44,
    y: 33,
    text: "Become a MoonVision Certified.Partner",
    description:
      "Do you have your own ideas or are you an expert from another industry? Benefit from the partnership with MoonVision with becoming our MoonVision Certified.Partner.",
    image: partnershipImg,
  }
  return (
    <Layout header={header} location={props.location}>
      <Container>
        <Row className="pt-5">
          <Col md={{ span: 8, offset: 2 }} className="text-center">
            <h2 style={{ fontSize: "2rem" }}>
              Certified.Partner Machine, microscope and camera producer
            </h2>
            <p>Benefit from a Europe-wide Network</p>
            <p>
              The satisfaction of our customers is one of our highest goals. As
              a software provider, we set the highest quality standards for
              ourselves and our hardware partners. Become a Certified Hardware
              Partner and inspire together with us international customers for
              efficient highly automated processes in manufacturing, quality
              assurance as well as product and process monitoring.
            </p>
          </Col>
        </Row>
        <Row className="py-5 text-center">
          <Col md="4">
            <div className="bluebox">
              <h6>Expertise</h6>
              <p>
                Benefit from our many years of experience and knowledge as a
                technology leader in the fields of IoT, AI, Deep Learning,
                Computer Vision, etc.
              </p>
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <h6>Overall Solution</h6>
              <p>
                Create intelligent overall solutions and thereby increase your
                turnover.
              </p>
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <h6>Marketing support</h6>
              <p>
                We support you with the presentation of your products in
                connection with MoonVision as a total solution with
                individualized marketing materials.
              </p>
            </div>
          </Col>
          <Col md={{ span: 4, offset: 2 }}>
            <div className="bluebox">
              <h6>Rollout processes</h6>
              <p>
                We enable efficient processes and create the optimal solution
                for your customers.
              </p>
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <h6>Sales training</h6>
              <p>
                You will learn which arguments you can use to inspire your
                customers in regular sales training courses.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="py-5">
          <Col md="6">
            <div className="grey-background p-2" style={{ height: "100%" }}>
              <Image
                filename={BasicPartnerLogo}
                alt="basic partner logo"
                className="mb-3"
              />
              <ul>
                <li>MoonVision Certificate and Certification Logo</li>
                <li>
                  MoonVision Toolbox test instance for regular and independent
                  testing
                </li>
                <li>Introduction to the MoonVision Toolbox</li>
              </ul>
              <Button>Become Partner</Button>
            </div>
          </Col>
          <Col md="6">
            <div className="grey-background p-2" style={{ height: "100%" }}>
              <Image
                filename={GoldPartnerLogo}
                alt="gold partner logo"
                className="mb-3"
              />
              <ul>
                <li>MoonVision Certificate and Certification Logo</li>
                <li>
                  MoonVision Toolbox test instance for regular and independent
                  testing with application of a demo model for test purposes
                </li>
                <li>
                  Support in marketing via various marketing channels and
                  inclusion in reference lists
                </li>
                <li>Remote Technical Support for plant commissioning</li>
              </ul>
              <Button>Become a partner</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
